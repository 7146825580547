@import "../../../styles/variables.module";
// feedback
.feedback {
  .nav {
    width: 100%;
    display: block;
    text-align: center;
    border-bottom: none;
    margin-top: 30px;
    @include large_device {
      flex-wrap: nowrap;
      display: flex;
      margin-top: 20px;
    }
    .navItem {
      display: inline-block;
      margin: 0 10px;
      @include large_device {
        margin: 0;
      }
      @include extra_small {
        width: calc(100% / 3);
      }
      .navLink {
        border: 1px solid transparent;
        border-radius: 50px;
        padding: 10px 20px;
        cursor: pointer;
        @include large_device {
          border-radius: $border-radius;
          padding: 10px 15px;
        }
        &[class$="active"] {
          background: $white-color;
          border-color: $secondary-color;
        }
        > img {
          width: 40px;
          height: 40px;
          @include large_device {
            width: 30px;
            height: 30px;
            margin-bottom: 4px;
          }
        }
        .rateDetail {
          margin-left: 10px;
          text-align: left;
          width: calc(100% - 50px);
          @include large_device {
            width: 100%;
            margin-left: 0;
            text-align: center;
          }
          .left {
            .name {
              @include large_device {
                font-size: 14px;
              }
              @include extra_small {
                word-break: break-word;
                font-size: 12px;
              }
            }
          }
          .name {
            font-size: 16px;
            color: $theme-custom-color;
            margin-bottom: 4px;
            text-transform: capitalize;
            font-weight: 500;
          }
          .star {
            line-height: 16px;
            img {
              width: 100px;
            }
          }
          .rating {
            @include large_device {
              justify-content: center;
              margin-top: 4px;
            }
            span {
              font-size: 40px;
              color: $black-color;
              font-weight: 500;
              line-height: normal;
              margin-left: 10px;
              @include extra_large {
                font-size: 30px;
              }
              @include large_device {
                font-size: 16px;
                margin-top: 4px;
                line-height: 23px;
                margin-left: 0;
              }
            }
          }
        }
      }
    }
    &.show {
      & > .navLink {
        background: $white-color;
        border-color: $secondary-color;
      }
    }
  }
  .tabContent {
    .feedbackSlider {
      margin-bottom: 100px;
      @include mobile_device {
        margin-bottom: 60px;
      }
      [class*="slide"] {
        padding: 20px 25px 0;
        @include large_device {
          padding: 10px 20px;
        }
        @include mobile_device {
          padding: 0 0 0 20px;
        }
      }
      [class*="-dots"] {
        bottom: -50px;
        left: 0;
        right: 0;
        @include mobile_device {
          bottom: -30px;
        }
      }
    }
    .feedbackDetail {
      background: $white-color;
      border-radius: 10px;
      position: relative;
      margin-top: 40px;
      padding: 60px 20px 30px;
      min-height: 240px;
      @include large_device {
        margin-top: 40px;
      }
      .info {
        .rating {
          height: 18px;
          & span {
            & span {
              margin-right: 8px;
              height: 18px;
              img {
                max-width: fit-content;
              }
            }
          }
        }
        .reviewTitle {
          margin-top: 10px;
          font-weight: 500;
          font-size: 16px;
          line-height: 28px;
          color: $theme-custom-color;
        }
        .content {
          margin-bottom: 0;
          margin-top: 10px;
          color: $desc-font;
          font-size: 14px;
          line-height: 24px;
        }
      }
      .customerDetail {
        background: $theme-color;
        padding: 6px 80px 6px 40px;
        border-radius: 20px 50px 50px 0;
        position: absolute;
        top: -20px;
        left: -20px;
        &:before {
          content: "";
          width: 30px;
          height: 30px;
          background: #0c183d;
          display: inline-block;
          position: absolute;
          left: 0;
          bottom: -15px;
          border-radius: 50% 0 0 50%;
          z-index: -1;
        }
        .name {
          color: $white-color;
          font-size: 16px;
          font-weight: 700;
        }
        .id {
          color: $white-color;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          display: inline-block;
        }
      }
      .customerImg {
        position: absolute;
        top: -40px;
        right: 30px;
        @include extra_large {
          right: 15px;
        }
        img {
          width: 80px;
          height: 80px;
          border-radius: 50px;
          border: 8px solid $body-color;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
    }
  }
  .overRating {
    margin-top: 60px;
    & > span {
      line-height: 24px;
      display: inline-block;
      font-size: 18px;
      @include mobile_device {
        font-size: 16px;
      }
      & > span {
        margin-right: 8px;
        height: 24px;
        vertical-align: text-bottom;
        img {
          max-width: fit-content;
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .outlineSecondaryBtn {
    background: transparent;
    color: $theme-custom-color;
    &:hover {
      background-color: $secondary-color;
    }
  }
}
